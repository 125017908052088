import styles from './Klaas.css'
import FitText from 'react-fittext'

export default function Klaas({ style }) {
  return (
    <div className={styles.container} style={style}>
      <div className={styles.content}>
        <FitText>
          <h1>Klaas Landsman</h1>
        </FitText>
        <FitText compressor={1.5} maxFontSize={16}>
          <p className={styles.txt}>Front-end developer</p>
        </FitText>
        <FitText compressor={1.5} maxFontSize={16}>
          <p className={styles.txt}>
            <a className={styles.link} href="mailto:mail@klaaslandsman.nl" title="E-mail with Klaas">
              mail@klaaslandsman.nl
            </a>
          </p>
        </FitText>
        <div className={styles.social}>
          <a className={styles.github} title="Visit Klaas at Github" href="https://github.com/klaasman">
            Klaas op Github
          </a>
          <a className={styles.linkedIn} title="Visit Klaas at LinkedIn" href="https://linkedin.com/in/klaaslandsman">
            Klaas op LinkedIn
          </a>
        </div>
      </div>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(getSchema()) }} />
    </div>
  )

  function getSchema() {
    return {
      '@context': 'http://schema.org/',
      '@type': 'Person',
      name: 'Klaas Landsman',
      email: 'mail@klaaslandsman.nl',
      jobTitle: 'Front-end developer',
      url: [
        'https://klaaslandsman.nl',
        'https://klaaslandsman.com',
        'https://github.com/klaasman',
        'https://linkedin.com/in/klaaslandsman',
      ],
      worksFor: {
        '@type': 'Organization',
        name: 'Kaliber',
        url: 'https://kaliber.net',
      },
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'NL',
        addressLocality: 'Utrecht, Netherlands',
      },
    }
  }
}
